import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { fetchAllData } from './DataService';
import { firebaseAuth, googleProvider, signInWithPopup, signInWithEmailAndPassword } from '../firebase/firebaseConfig';
import { onAuthStateChanged, signOut } from "firebase/auth";

const DataContext = createContext();

const dataReducer = (state, action) => {
    switch (action.type) {
        case 'SET_ALL_DATA':
            return { ...state, allData: action.payload };
        case 'SET_APP_USER':
            return { ...state, appUser: action.payload };
        case 'SET_APP_USER_SETTINGS':
            return { ...state, appUserSettings: action.payload };
        case 'SET_SELECTED_CONTENT':
            return { ...state, selectedContent: action.payload };
        case 'SET_PLAYLIST_CONTENT':
            return { ...state, playlistContent: action.payload };
        case 'SET_USER_PLAYLISTS':
            return { ...state, userPlaylists: action.payload };
        case 'SET_USER_FAVORITES':
            return { ...state, userFavorites: action.payload };
        default:
            return state;
    }
};

const DataProvider = ({ children }) => {
    const [state, dispatch] = useReducer(dataReducer, { allData: null, appUser: null });

    useEffect(() => {
        const fetchAllDataAndUpdateState = async () => {
            try {
                const allData = await fetchAllData();
                dispatch({ type: 'SET_ALL_DATA', payload: allData });
            } catch (error) {
                console.error('Could not fetch content data', error);
            }
        };

        fetchAllDataAndUpdateState();

        // Suscribirse a los cambios de autenticación de Firebase
        const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
            dispatch({ type: 'SET_APP_USER', payload: user || null });
        });

        return () => unsubscribe();
    }, []);

    const loginWithGoogle = async () => {
        try {
            const result = await signInWithPopup(firebaseAuth, googleProvider);
            dispatch({ type: 'SET_APP_USER', payload: result.user });
            return result.user;
        } catch (error) {
            console.error("Google Sign-In Error: ", error);
            throw error;
        }
    };

    const loginWithEmail = async (email, password) => {
        try {
            const result = await signInWithEmailAndPassword(firebaseAuth, email, password);
            dispatch({ type: 'SET_APP_USER', payload: result.user });
            return result.user;
        } catch (error) {
            console.error("Email Sign-In Error: ", error);
            throw error;
        }
    };

    const logout = async () => {
        try {
            await signOut(firebaseAuth);
            dispatch({ type: 'SET_APP_USER', payload: null });
        } catch (error) {
            console.error("Sign-Out Error: ", error);
        }
    };

    return (
        <DataContext.Provider value={{ state, dispatch, loginWithGoogle, loginWithEmail, logout }}>
            {children}
        </DataContext.Provider>
    );
};

const useData = () => {
    const context = useContext(DataContext);
    if (!context) {
        throw new Error('useData must be used within a DataProvider');
    }
    return context;
};

export {
    DataProvider,
    useData
};
